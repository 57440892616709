import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import Grid from "../components/Grid"

import css from "./404.module.scss"

import missingImage from "../assets/images/climb-mountain.svg"

const metadata = {
  title: "You’ve found a page that doesn’t exist!",
  description:
    "Uh oh, probably not what you were looking for. To continue, head to our homepage instead.",
}

// Prevent flash of 404 during page redirect for client-only paths created in gatsby-node
// See: https://github.com/gatsbyjs/gatsby/issues/5329#issuecomment-484741119
const browser = typeof window !== "undefined" && window

const NotFoundPage = (props) =>
  browser && (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <Grid className={css.container} centeredClassName={css.centered}>
        <img
          src={missingImage}
          alt="Mountain climber looking into the distance"
        />
        <h1>You’ve found a page that doesn’t exist!</h1>
        <h3>
          {" "}
          Uh oh, probably not what you were looking for. To continue, head to
          our <Link to="/">homepage</Link> instead.
        </h3>
      </Grid>
    </Layout>
  )

export default NotFoundPage
